<template>
  <div class="main">
    <div class="app-settings content-body">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          Settings
        </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h4>App Settings</h4>
        </a-col>
      </a-row>
      <a-row class="body">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane key="1" tab="General">
              <a-form ref="appFormRef" :model="appForm" :rules="appRules" layout="vertical">
                <a-form-item label="Client Logo" name="image">
                  <a-upload :action="imageUploadUrl" :headers="headers" list-type="picture-card" v-model:file-list="fileList"
                    :multiple="false" @preview="handlePreview" @change="handleChange" :remove="handleRemove">
                    <div v-if="fileList.length <= 0">
                      <PlusOutlined />
                      <div class="ant-upload-text">Upload</div>
                    </div>
                  </a-upload>
                  <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                  </a-modal>
                </a-form-item>
              </a-form>
              <a-button type="primary" class="updateBtn" @click.prevent="validateForm">Update</a-button>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Users" force-render>
              <a-button type="primary" class="addUserBtn" @click.prevent="showAddUserModal">Add User</a-button>
              <AddUser :showUserModel="showUserModel" @refreshUserList="refreshUserList" />
              <ListUser :refreshUserListCount="refreshUserListCount" />
            </a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, UnwrapRef, onMounted } from 'vue'
import { HomeOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'

import validate from '../../../services/validator'
import profileService from '../../../services/profile'
import commonServices from '../../../services/common'

import AddUser from '@/components/agency/settings/AddUser.vue'
import ListUser from '@/components/agency/settings/ListUser.vue'

function getBase64 (file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  percent?: number;
  url?: string;
  preview?: string;
  originFileObj?: any;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

interface AppSettings {
  client_logo?: string;
}

export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined,
    AddUser,
    ListUser
  },
  setup (props, { emit }) {
    const loading = ref<boolean>(false)
    const headers = ref<object>({
      token: localStorage.accessToken
    })
    const loginAs = ref(new URLSearchParams(window.location.search).get('loginAs'))
    const imageUploadUrl = process.env.VUE_APP_API_URL + process.env.VUE_APP_FILE_UPLOAD
    const fileList = ref<FileItem[]>([])
    const appFormRef = ref()
    const appRules = validate.appSettingsValidation
    const previewVisible = ref<boolean>(false)
    const previewImage = ref<string | undefined>('')
    const showUserModel = ref<number>(0)
    const refreshUserListCount = ref<number>(0)
    const showAddUserModal = () => {
      showUserModel.value++
    }
    const appForm: UnwrapRef<AppSettings> = reactive({
      client_logo: ''
    })
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const refreshUserList = async () => {
      console.log('refreshUserList')
      refreshUserListCount.value++
    }
    const handleCancel = () => {
      previewVisible.value = false
    }
    const handlePreview = async (file: FileItem) => {
      if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj)) as string
      }
      previewImage.value = file.url || file.preview
      previewVisible.value = true
    }
    const handleChange = ({ fileList: newFileList }: FileInfo) => {
      if (fileList.value.length > 0) {
        const s3Response: any = fileList.value[0].response
        fileList.value = newFileList
        if (s3Response) {
          appForm.client_logo = s3Response.Location
        }
      }
    }
    const handleRemove = (file: FileItem) => {
      const index = fileList.value.indexOf(file)
      const newFileList = fileList.value.slice()
      newFileList.splice(index, 1)
      fileList.value = newFileList
      appForm.client_logo = ''
    }
    const getClientDetails = async () => {
      try {
        const profile = commonServices.getCurrentProfile()
        const responce = await profileService.getProfileDetails(profile._id)
        if (loginAs.value) {
          localStorage.setItem('loginAsProfile', JSON.stringify(profile))
        } else {
          localStorage.setItem('profile', JSON.stringify(profile))
        }
        if (responce.data.client_logo) {
          fileList.value.push(
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: responce.data.client_logo
            }
          )
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const updateAppSettings = async (data) => {
      try {
        const profile = commonServices.getCurrentProfile()
        const profileType = profile.profile_type
        const address = profile.address
        profile.address = profile.address._id
        profile.profile_type = profile.profile_type._id
        profile.client_logo = data.client_logo
        await profileService.updateProfile(profile._id, profile)
        profile.profile_type = profileType
        profile.address = address
        if (loginAs.value) {
          localStorage.setItem('loginAsProfile', JSON.stringify(profile))
        } else {
          localStorage.setItem('profile', JSON.stringify(profile))
        }
        localStorage.setItem('clientLogo', profile.client_logo)
        emit('updateMenu')
        await notify('Success', 'Updated successfully', 'success')
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const validateForm = async () => {
      appFormRef.value
        .validate()
        .then(() => {
          updateAppSettings(appForm)
        })
        .catch((error: ValidateErrorEntity<AppSettings>) => {
          console.log('error', error)
        })
    }
    onMounted(() => {
      getClientDetails()
    })
    return {
      loading,
      headers,
      imageUploadUrl,
      appFormRef,
      appRules,
      handleCancel,
      handlePreview,
      handleChange,
      handleRemove,
      appForm,
      fileList,
      previewImage,
      previewVisible,
      validateForm,
      activeKey: ref('1'),
      showUserModel,
      showAddUserModal,
      refreshUserList,
      refreshUserListCount
    }
  }
})
</script>
<style lang="scss">
.app-settings {
  .header {
    background: #fff;
    padding: 15px 20px;
    margin: 20px 0px 10px;
    border-radius: 8px;
    h4 {
      color: #646464;
      font-family: "TT Norms Pro Medium";
      font-size: 18px;
      text-align: left;
      margin: 0;
    }
  }
  .body {
    background: #ffffff;
    min-height: 200px;
    box-shadow: 0px 5px 10px #00000003;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    .has-error .ant-form-explain {
      text-align: left;
    }
    .ant-input-affix-wrapper{
      border-radius: 5px;
    }
    .ant-tabs {
      .ant-tabs-nav-container {
        text-align: left;
      }
    }
    .ant-form {
      margin: 50px auto;
      justify-content: space-between;
      label {
        color: #646464 !important;
      }
      .ant-upload-picture-card-wrapper {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        .ant-upload-select-picture-card {
          margin-bottom: 0;
          background-color: #d9d9d9;
          border: none;
        }
      }
    }
    .updateBtn {
      background: #72CF97;
      border: 1px solid #72CF97;
      border-radius: 5px;
      width: 250px;
      margin: 0 0 30px 0;
    }
    .addUserBtn {
      background: #72CF97;
      border: 1px solid #72CF97;
      border-radius: 5px;
      float: right;
      margin-right: 30px;
    }
  }
}
</style>
