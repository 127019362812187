<template>
  <div class="list-user">
    <div class="body">
      <a-row class="filter">
        <div v-if="!showResendButton"></div>
        <a-button type="primary" v-if="showResendButton" @click="resendCredentials()" :loading="loading">
          Resend Login
          <template #icon><SendOutlined /></template>
        </a-button>
      </a-row>
      <a-table :row-selection="rowSelection" :columns="columns" :data-source="users" class="users ant-table-striped"
          :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
          :pagination="{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
          }"
          rowKey="_id"
        >
          <template #nameSection="{ record }">
            <div class="profileimg">
              <span class="name">{{ record.first_name }} {{ record.last_name }}</span>
            </div>
          </template>
          <template #status="{ record }">
            <a-tooltip>
              <template #title>Activate Client</template>
              <CheckCircleOutlined @click="changeUserStatus(record, true, index)" v-if="!record.is_active" :style="{ fontSize: '15px', color: '#52c41a' }" />
            </a-tooltip>
            <a-tooltip>
              <template #title>De-Activate Client</template>
              <CloseCircleOutlined @click="changeUserStatus(record, false, index)" v-if="record.is_active" :style="{ fontSize: '15px', color: '#ff7875' }" />
            </a-tooltip>
          </template>
          <template #action="{ record }">
            <div class="actionblock">
              <img src="../../../assets/images/deleteicon.svg" @click="confirmDeleteCandidate(record, index)" />
              <!-- <img src="../../../assets/images/editicon.svg" @click="editCandidate(record)" /> -->
            </div>
          </template>
        </a-table>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, unref, createVNode, onMounted, watch } from 'vue'
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { CloseCircleOutlined, SendOutlined, CheckCircleOutlined } from '@ant-design/icons-vue'
import { notification, Modal } from 'ant-design-vue'

import profileService from '../../../services/profile'
import commonServices from '../../../services/common'

import IProfile from '../../../interface/profile'

type Key = ColumnProps['key'];
export default defineComponent({
  components: {
    SendOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined
  },
  props: ['refreshUserListCount'],
  setup (props, { emit }) {
    const loading = ref<boolean>(false)
    const users = ref<Array<any>>([])
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const columns = [
      {
        title: 'Name',
        onFilter: (value, record) => record.first_name.indexOf(value) === 0,
        sorter: (a, b) => a.first_name.length - b.first_name.length,
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'nameSection' }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        onFilter: (value, record) => record.email.indexOf(value) === 0,
        sorter: (a, b) => a.email.length - b.email.length,
        sortDirections: ['descend', 'ascend'],
        scopedSlots: { customRender: 'email' }
      },
      {
        title: 'Status',
        align: 'center',
        slots: { customRender: 'status' }
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        slots: { customRender: 'action' }
      }
    ]
    const selectedRowKeys = ref<Key[]>([])
    const showResendButton = ref<boolean>(false)
    const searchString = ref<string>('')
    const candidateData = ref<Array<IProfile>>([])
    const getUsers = async () => {
      const clientId = commonServices.getClientId()
      const responce = await profileService.getUsers(clientId)
      users.value = responce.data
      candidateData.value = responce.data
    }
    const editCandidate = async (candidate) => {
      // router.push(`/client/candidates/edit/${candidate._id}${loginAs.value}`)
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const deleteCandidate = async (profile, index) => {
      try {
        const clientId = commonServices.getClientId()
        const subscriptionId = commonServices.getCurrentSubscription()._id
        const subscription = await profileService.deleteCandidate({ profileId: profile._id, clientId: clientId, subscriptionId: subscriptionId })
        localStorage.subscription = JSON.stringify(subscription.data)
        users.value.splice(index, 1)
        await notify('Success', 'Client deleted successfully', 'success')
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const confirmDeleteCandidate = async (profile, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(CloseCircleOutlined),
        content: createVNode('div', {}, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          await deleteCandidate(profile, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const resendCredentials = async () => {
      try {
        loading.value = true
        await profileService.resendCredentials({ profileIds: selectedRowKeys.value })
        showResendButton.value = false
        loading.value = false
        selectedRowKeys.value = []
        await notify('Success', 'Login credentials send successfully', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    // select table rows checkbox
    const onSelectChange = (changableRowKeys: Key[]) => {
      selectedRowKeys.value = changableRowKeys
      if (changableRowKeys.length > 0) {
        showResendButton.value = true
      } else {
        showResendButton.value = false
      }
    }
    const changeUserStatus = async (client, status) => {
      try {
        client.is_active = status
        client.profile_type = client.profile_type._id
        await profileService.activateDeactivate(client._id, client)
        getUsers()
        notify('Success', (status ? 'User activated' : 'User de-activated'), 'success')
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: unref(selectedRowKeys),
        onChange: onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: 'all-data',
            text: 'Select All Data',
            onSelect: (changableRowKeys: Key[]) => {
              console.log('changableRowKeys', changableRowKeys)
              let newSelectedRowKeys = []
              newSelectedRowKeys = changableRowKeys
              selectedRowKeys.value = newSelectedRowKeys
            }
          },
          {
            key: 'odd',
            text: 'Select Odd Row',
            onSelect: (changableRowKeys: Key[]) => {
              let newSelectedRowKeys = []
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return false
                }
                return true
              })
              selectedRowKeys.value = newSelectedRowKeys
            }
          },
          {
            key: 'even',
            text: 'Select Even Row',
            onSelect: (changableRowKeys: Key[]) => {
              let newSelectedRowKeys = []
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return true
                }
                return false
              })
              selectedRowKeys.value = newSelectedRowKeys
            }
          }
        ]
      }
    })
    watch(() => props.refreshUserListCount, () => {
      getUsers()
    })
    onMounted(() => {
      getUsers()
    })
    return {
      columns,
      searchString,
      editCandidate,
      selectedRowKeys,
      rowSelection,
      confirmDeleteCandidate,
      notify,
      users,
      showResendButton,
      resendCredentials,
      loading,
      loginAs,
      changeUserStatus
    }
  }
})
</script>
<style lang="scss">
.list-user {
  padding: 10px;
  .body {
    .title {
      color: #707070;
      float: left;
      font-size: 20px;
      text-transform: capitalize;
      font-family: "TT Norms Pro Medium";
    }
    .filter {
      display: flex;
      justify-content: space-between;
      margin: 30px 0 0 0;
      .noofentries {
        display: flex;
        align-items: center;
        .ant-select {
          margin: 0 5px;
        }
      }
      .ant-input-affix-wrapper {
        width: 200px;
      }
      .ant-select,
      .ant-btn {
        float: left;
      }
      .ant-btn {
        background: #7EAF1A;
        border-color: #7EAF1A;
        border-radius: 20px;
        font-family: "TT Norms Pro Medium";
      }
    }
    .users {
      margin-top: 20px;
      font-family: "TT Norms Pro Medium";
      .profileimg {
        display: flex;
        align-items: center;
        img {
          width: 35px;
          height: 35px;
          line-height: 35px;
          font-size: 20px;
          transform: scale(0.8);
          border-radius: 50%;
          margin: 0 5px 0 0;
        }
        span {
          color: #727cf5;
          text-transform: capitalize;
        }
      }
      .actionblock {
        img {
          width: 30px;
          height: 30px;
          cursor: pointer;
          margin: 0 5px;
          float: right;
        }
        .login-as {
          width: 28px;
          height: 28px;
          background: #2fcc71;
          color: #ffffff;
          line-height: 30px;
          border-radius: 50%;
          float: right;
        }
      }
      .ant-table {
        color: #646464;
        .ant-table-thead {
          tr {
            th {
              &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
              }
              &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }
          }
        }
        .ant-table-tbody {
          tr {
            td {
              &:nth-child(3) {
                font-family: "TT Norms Pro Regular";
              }
            }
          }
        }
      }
      .ant-pagination {
        .ant-pagination-options {
          // display: none;
        }
        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          border-radius: 50%;
          background-color: #ededed;
          border: none;
        }
        .ant-pagination-item {
          background-color: #ededed;
          margin-right: 0px;
          border: none;
          font-family: "TT Norms Pro Regular";
        }
        li:nth-child(2) {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
        li:nth-last-child(3n) {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
        .ant-pagination-next {
          margin-left: 8px;
        }
        .ant-pagination-item-active {
          background-color: #ededed;
          a {
            background: #6f64f8;
            color: #fff;
            border-radius: 50%;
          }
        }
      }
      .totalentries {
        font-family: "TT Norms Pro Regular";
        text-align: left;
        margin: -40px 0 0 0;
        color: #646464;
        p {
          margin: 0;
        }
      }
    }
  }
}
</style>
<style scoped>
.ant-table-striped :deep(.table-striped) {
  background-color: #f7fafc;
}
</style>
