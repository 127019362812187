<template>
  <a-modal title="Add New User" :visible="visible" @ok="validateUserForm" @cancel="handleCancel" width="1000px">
    <div class="add-user">
      <a-form ref="userFormRef" :model="userForm" :rules="userRules" layout="vertical">
        <a-form-item label="First Name" required has-feedback name="first_name">
          <a-input v-model:value="userForm.first_name" size="large" type="text" placeholder="Enter first name" allow-clear autocomplete="off" />
        </a-form-item>
        <a-form-item label="Last Name" required has-feedback name="last_name">
          <a-input v-model:value="userForm.last_name" size="large" type="text" placeholder="Enter last name" allow-clear autocomplete="off" />
        </a-form-item>
        <a-form-item label="Email" required has-feedback name="email">
          <a-input v-model:value="userForm.email" size="large" type="text" placeholder="Enter your email" allow-clear autocomplete="off" />
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, UnwrapRef, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { notification } from 'ant-design-vue'

import validate from '../../../services/validator'
import profileService from '../../../services/profile'
import commonServices from '../../../services/common'

import IClient from '../../../interface/profile'

interface Users {
  first_name: string;
  lastName: string;
  email: string;
}

export default defineComponent({
  props: ['showUserModel', 'editUser'],
  setup (props, { emit }) {
    const visible = ref<boolean>(false)
    const userFormRef = ref()
    const userRules = validate.newUser
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const userForm: UnwrapRef<IClient> = reactive({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      profile_image: '',
      client_name: '',
      client_logo: '',
      certificate_access: false,
      created_by: '',
      profile_type: '',
      is_active: true,
      status: 'paid',
      send_login: true,
      address: '',
      address_details: {}
    })
    const handleCancel = () => {
      console.log('Clicked cancel button')
      visible.value = false
    }
    const edituser = async (data) => {
      console.log('e', data)
    }
    const adduser = async (data) => {
      try {
        const clientId = commonServices.getClientId()
        const responce = await profileService.createNewUser(data, clientId)
        if (typeof responce.data === 'string') {
          await notify('Error', responce.data, 'error')
          return
        }
        emit('refreshUserList', responce)
        notify('Success', 'User added successfully', 'success')
        visible.value = false
      } catch (error) {
        console.log('error', error)
      }
    }
    const validateUserForm = () => {
      userFormRef.value
        .validate()
        .then(() => {
          if (props.editUser) {
            edituser(userForm)
          } else {
            adduser(userForm)
          }
        })
        .catch((error: ValidateErrorEntity<IClient>) => {
          console.log('error', error)
        })
    }
    watch(() => props.showUserModel, () => {
      visible.value = true
    })
    return {
      visible,
      userFormRef,
      userForm,
      handleCancel,
      validateUserForm,
      userRules,
      adduser,
      edituser
    }
  }
})
</script>
<style lang="scss">
</style>
