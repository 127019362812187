
import { defineComponent, ref, computed, unref, createVNode, onMounted, watch } from 'vue'
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { CloseCircleOutlined, SendOutlined, CheckCircleOutlined } from '@ant-design/icons-vue'
import { notification, Modal } from 'ant-design-vue'

import profileService from '../../../services/profile'
import commonServices from '../../../services/common'

import IProfile from '../../../interface/profile'

type Key = ColumnProps['key'];
export default defineComponent({
  components: {
    SendOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined
  },
  props: ['refreshUserListCount'],
  setup (props, { emit }) {
    const loading = ref<boolean>(false)
    const users = ref<Array<any>>([])
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const columns = [
      {
        title: 'Name',
        onFilter: (value, record) => record.first_name.indexOf(value) === 0,
        sorter: (a, b) => a.first_name.length - b.first_name.length,
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'nameSection' }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        onFilter: (value, record) => record.email.indexOf(value) === 0,
        sorter: (a, b) => a.email.length - b.email.length,
        sortDirections: ['descend', 'ascend'],
        scopedSlots: { customRender: 'email' }
      },
      {
        title: 'Status',
        align: 'center',
        slots: { customRender: 'status' }
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        slots: { customRender: 'action' }
      }
    ]
    const selectedRowKeys = ref<Key[]>([])
    const showResendButton = ref<boolean>(false)
    const searchString = ref<string>('')
    const candidateData = ref<Array<IProfile>>([])
    const getUsers = async () => {
      const clientId = commonServices.getClientId()
      const responce = await profileService.getUsers(clientId)
      users.value = responce.data
      candidateData.value = responce.data
    }
    const editCandidate = async (candidate) => {
      // router.push(`/client/candidates/edit/${candidate._id}${loginAs.value}`)
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const deleteCandidate = async (profile, index) => {
      try {
        const clientId = commonServices.getClientId()
        const subscriptionId = commonServices.getCurrentSubscription()._id
        const subscription = await profileService.deleteCandidate({ profileId: profile._id, clientId: clientId, subscriptionId: subscriptionId })
        localStorage.subscription = JSON.stringify(subscription.data)
        users.value.splice(index, 1)
        await notify('Success', 'Client deleted successfully', 'success')
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const confirmDeleteCandidate = async (profile, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(CloseCircleOutlined),
        content: createVNode('div', {}, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          await deleteCandidate(profile, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const resendCredentials = async () => {
      try {
        loading.value = true
        await profileService.resendCredentials({ profileIds: selectedRowKeys.value })
        showResendButton.value = false
        loading.value = false
        selectedRowKeys.value = []
        await notify('Success', 'Login credentials send successfully', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    // select table rows checkbox
    const onSelectChange = (changableRowKeys: Key[]) => {
      selectedRowKeys.value = changableRowKeys
      if (changableRowKeys.length > 0) {
        showResendButton.value = true
      } else {
        showResendButton.value = false
      }
    }
    const changeUserStatus = async (client, status) => {
      try {
        client.is_active = status
        client.profile_type = client.profile_type._id
        await profileService.activateDeactivate(client._id, client)
        getUsers()
        notify('Success', (status ? 'User activated' : 'User de-activated'), 'success')
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: unref(selectedRowKeys),
        onChange: onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: 'all-data',
            text: 'Select All Data',
            onSelect: (changableRowKeys: Key[]) => {
              console.log('changableRowKeys', changableRowKeys)
              let newSelectedRowKeys = []
              newSelectedRowKeys = changableRowKeys
              selectedRowKeys.value = newSelectedRowKeys
            }
          },
          {
            key: 'odd',
            text: 'Select Odd Row',
            onSelect: (changableRowKeys: Key[]) => {
              let newSelectedRowKeys = []
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return false
                }
                return true
              })
              selectedRowKeys.value = newSelectedRowKeys
            }
          },
          {
            key: 'even',
            text: 'Select Even Row',
            onSelect: (changableRowKeys: Key[]) => {
              let newSelectedRowKeys = []
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return true
                }
                return false
              })
              selectedRowKeys.value = newSelectedRowKeys
            }
          }
        ]
      }
    })
    watch(() => props.refreshUserListCount, () => {
      getUsers()
    })
    onMounted(() => {
      getUsers()
    })
    return {
      columns,
      searchString,
      editCandidate,
      selectedRowKeys,
      rowSelection,
      confirmDeleteCandidate,
      notify,
      users,
      showResendButton,
      resendCredentials,
      loading,
      loginAs,
      changeUserStatus
    }
  }
})
