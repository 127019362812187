
import { defineComponent, ref, reactive, UnwrapRef, onMounted } from 'vue'
import { HomeOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'

import validate from '../../../services/validator'
import profileService from '../../../services/profile'
import commonServices from '../../../services/common'

import AddUser from '@/components/agency/settings/AddUser.vue'
import ListUser from '@/components/agency/settings/ListUser.vue'

function getBase64 (file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  percent?: number;
  url?: string;
  preview?: string;
  originFileObj?: any;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

interface AppSettings {
  client_logo?: string;
}

export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined,
    AddUser,
    ListUser
  },
  setup (props, { emit }) {
    const loading = ref<boolean>(false)
    const headers = ref<object>({
      token: localStorage.accessToken
    })
    const loginAs = ref(new URLSearchParams(window.location.search).get('loginAs'))
    const imageUploadUrl = process.env.VUE_APP_API_URL + process.env.VUE_APP_FILE_UPLOAD
    const fileList = ref<FileItem[]>([])
    const appFormRef = ref()
    const appRules = validate.appSettingsValidation
    const previewVisible = ref<boolean>(false)
    const previewImage = ref<string | undefined>('')
    const showUserModel = ref<number>(0)
    const refreshUserListCount = ref<number>(0)
    const showAddUserModal = () => {
      showUserModel.value++
    }
    const appForm: UnwrapRef<AppSettings> = reactive({
      client_logo: ''
    })
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const refreshUserList = async () => {
      console.log('refreshUserList')
      refreshUserListCount.value++
    }
    const handleCancel = () => {
      previewVisible.value = false
    }
    const handlePreview = async (file: FileItem) => {
      if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj)) as string
      }
      previewImage.value = file.url || file.preview
      previewVisible.value = true
    }
    const handleChange = ({ fileList: newFileList }: FileInfo) => {
      if (fileList.value.length > 0) {
        const s3Response: any = fileList.value[0].response
        fileList.value = newFileList
        if (s3Response) {
          appForm.client_logo = s3Response.Location
        }
      }
    }
    const handleRemove = (file: FileItem) => {
      const index = fileList.value.indexOf(file)
      const newFileList = fileList.value.slice()
      newFileList.splice(index, 1)
      fileList.value = newFileList
      appForm.client_logo = ''
    }
    const getClientDetails = async () => {
      try {
        const profile = commonServices.getCurrentProfile()
        const responce = await profileService.getProfileDetails(profile._id)
        if (loginAs.value) {
          localStorage.setItem('loginAsProfile', JSON.stringify(profile))
        } else {
          localStorage.setItem('profile', JSON.stringify(profile))
        }
        if (responce.data.client_logo) {
          fileList.value.push(
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: responce.data.client_logo
            }
          )
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const updateAppSettings = async (data) => {
      try {
        const profile = commonServices.getCurrentProfile()
        const profileType = profile.profile_type
        const address = profile.address
        profile.address = profile.address._id
        profile.profile_type = profile.profile_type._id
        profile.client_logo = data.client_logo
        await profileService.updateProfile(profile._id, profile)
        profile.profile_type = profileType
        profile.address = address
        if (loginAs.value) {
          localStorage.setItem('loginAsProfile', JSON.stringify(profile))
        } else {
          localStorage.setItem('profile', JSON.stringify(profile))
        }
        localStorage.setItem('clientLogo', profile.client_logo)
        emit('updateMenu')
        await notify('Success', 'Updated successfully', 'success')
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const validateForm = async () => {
      appFormRef.value
        .validate()
        .then(() => {
          updateAppSettings(appForm)
        })
        .catch((error: ValidateErrorEntity<AppSettings>) => {
          console.log('error', error)
        })
    }
    onMounted(() => {
      getClientDetails()
    })
    return {
      loading,
      headers,
      imageUploadUrl,
      appFormRef,
      appRules,
      handleCancel,
      handlePreview,
      handleChange,
      handleRemove,
      appForm,
      fileList,
      previewImage,
      previewVisible,
      validateForm,
      activeKey: ref('1'),
      showUserModel,
      showAddUserModal,
      refreshUserList,
      refreshUserListCount
    }
  }
})
